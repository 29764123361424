.askastudent-auth-form {
  .header {
    .head {
      font-size: 1.6rem;
      font-weight: 600;
    }
    .sub-head {
      font-family: 'Muli';
      font-weight: 500;
      .txt-primary {
        font-weight: 700;
      }
    }
  }
  .login-btn,
  .verify-btn {
    padding: 0.625rem;
    height: auto;
  }
  .verify-btn {
    padding: 0.625rem 3rem;
  }
  .auth-form-section {
    .role-section-wrapper {
      .ant-form-item-label {
        text-align: center;
        margin-bottom: 0.3125rem;
        label {
          font-size: 1rem;
          color: var(--black);
        }
      }
      .ant-radio-button-wrapper {
        font-size: 1rem;
        &:hover {
          color: var(--primary);
        }
      }
      .ant-radio-button-wrapper-checked {
        color: var(--white);
        &:hover {
          color: var(--white);
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .askastudent-auth-form {
    .auth-form-section .role-section-wrapper .ant-radio-button-wrapper {
      font-size: 0.7rem;
    }
  }
}
