.animation-btn {
  position: relative;
  text-decoration: none;
  z-index: 9;
  &::before {
    content: '';
    position: absolute;
    width: 0px;
    height: 100%;
    background: var(--black);
    top: 0px;
    right: 0px;
    transition: 0.5s;
    z-index: -1;
  }
  &:hover {
    color: var(--white) !important;
    border: none;
    &::before {
      right: auto;
      left: 0;
      width: 100%;
      transition: 0.6s;
    }
  }
}

.primary-btn {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
  &::after {
    height: 100%;
    left: 0;
    top: 0;
    width: 0;
  }
}
