/**
 * This file is part of [Nainik | Software Developer]
 *
 * (c) 2021 [Nainik Mehta] <[nainikmehta25@gmail.com]>
 *
 * --------------------------------------------------
 *
 * @module app.v1.nainikApp
 * @description Base Css File
 * @author [Nainik Mehta] <[https://www.linkedin.com/in/nainik-mehta-25nk12/]>
 * @version 1.0.0
 *
 * --------------------------------------------------
 */

@import 'antd/dist/reset.css';
@import 'react-phone-number-input/style.css';

@import '@core/scss/core.scss';

@font-face {
  font-family: 'FilsonPro';
  src: url('@core/fonts/FilsonPro/FilsonProBlack.otf');
  src: url('@core/fonts/FilsonPro/FilsonProBold.otf');
  src: url('@core/fonts/FilsonPro/FilsonProMedium.otf');
}

@font-face {
  font-family: 'Muli';
  src: url('@core/fonts/Muli/Muli-Bold.ttf');
  src: url('@core/fonts/Muli/Muli-Semi-BoldItalic.ttf');
  src: url('@core/fonts/Muli/Muli-SemiBold.ttf');
  src: url('@core/fonts/Muli/Muli.ttf');
}

.html2canvas-container {
  width: 1024px;
  height: 3000px;
}

body,
.ant-menu,
.ant-col {
  margin: 0 !important;
  font-family:
    FilsonPro,
    Muli,
    ui-sans-serif,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    'Helvetica Neue',
    Arial,
    'Noto Sans',
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'Noto Color Emoji' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--black-text-base);
  font-size: 1rem;
  font-weight: 400;
  padding: 0;
}

html {
  scroll-behavior: smooth;
  body {
    height: 100vh;
    font-size: 1rem;
  }
}
