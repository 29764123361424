.app-header-filter {
  padding: 10px 20px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 14px #0000001a;
  opacity: 1;
}

@media screen and (max-width: 576px) {
  .app-header-filter {
    padding: 10px;
    .filter-card {
      margin-left: 0 !important;
    }
  }
}
