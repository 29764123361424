:root {
  // colors
  --primary: #000000;
  --app-secondary: #ee3566;
  --app-error: #ff4d4f;
  --link: #1677ff;

  --white: #fff;
  --bg-blue: #eff6fe;

  --black: #000000;
  --blue: #0a61d0;
  --table-head: #e4eefa;
  --black-text-base: #222222;
  --gray-text: #838383;
  --gray-50: #f6f6f6;
  --gray-100: #babfc7; // --gray-lightest
  --gray-200: #ededed; // --gray-lighter
  --gray-300: #dae1e7; // --gray-light
  --gray-400: #636363;
  --gray-450: #646464;
  --gray-500: #adb5bd;
  --gray-600: #b8c2cc; // --gray
  --gray-700: #4e5154;
  --gray-800: #1e1e1e; // --gray-dark
  --gray-900: #2a2e30;

  --green-200: #56d179;
  // Add more custom properties as needed

  // Add more custom properties as needed
}
