//

// Color scheme
.color-semi-black {
  color: var(--semi-black);
}
.color-black {
  color: var(--black);
}
.color-white {
  color: var(--white) !important;
}
.color-primary {
  color: var(--primary) !important;
}

.bg-color-primary {
  background-color: var(--primary);
}

.ant-btn {
  box-shadow: none;
}

.page-container {
  min-height: calc(100vh - 100px);
  margin: 0;
  display: flex;
  align-items: center;
  padding: 25px 5% 25px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.dot {
  width: 8px;
  height: 8px;
  margin-right: 8px;
  border-radius: 50%;
}

.error-form {
  .ant-form-item-control {
    min-height: 50px !important;
    height: 50px !important;
  }
}
.ant-form-item-explain-error {
  font-size: 12px;
  font-weight: 500;
  display: inline-block;
  width: 100%;
  white-space: nowrap !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

.shift-table {
  .ant-table-title
    + .ant-table-container
    table
    > thead
    > tr:first-child
    th:first-child {
    border-radius: 7px 0px 0px 0px;
  }

  .ant-table-title
    + .ant-table-container
    table
    > thead
    > tr:first-child
    th:last-child {
    border-radius: 0px 7px 0px 0px;
  }

  .ant-table.ant-table-bordered > .ant-table-title {
    border: none;
  }

  .ant-table-content > table > tbody > tr > td {
    border: 1px solid #e2ebf3;
  }

  .ant-table-tbody > tr > td {
    font-weight: 400;
    font-size: 16px;
  }

  .imp-text {
    color: var(--blue);
    font-size: 16px;
    font-weight: 500;
  }

  .ant-table-thead > tr > th {
    background: var(--table-head);
    font-family: 'Muli';
    font-size: 14px;
    font-weight: 700;
    padding: 12px 8px !important;
    color: var(--black-text-base) !important;

    // border-radius: 7px 7px 0px 0px;
  }
}

.ant-form-item-control {
  min-height: 50px !important;
  height: 50px !important;
}

@media only screen and (min-width: 992px) {
  .d-md-none {
    display: none;
  }
}
@media only screen and (max-width: 992px) {
  .col-sm-order-2 {
    order: 2;
  }
  .col-sm-order-1 {
    order: 1;
  }
  .ant-form-item-explain-error {
    font-size: 0.7rem;
  }
  .page-container {
    max-width: 960px;
  }
  .d-sm-none {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .page-container {
    max-width: 720px;
  }
}

@media only screen and (max-width: 576px) {
  .page-container {
    min-height: auto;
    max-width: 540px;
  }
}
