$body-color: white;
$wrapper-color: white;
$corpus-color: #ff00ff;
$text-color: black;

body,
html {
  margin: 0;
  background: $body-color;
}

.vps-app-spinner {
  min-height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
}

#wrapper {
  filter: contrast(40);
  background: $wrapper-color;
  position: fixed;
  width: 100px;
  height: 100px;
  z-index: 9;
  overflow: hidden;
  top: 50%;
  left: 50%;
  margin: -50px 0 0 -50px;

  animation-duration: 8s;
  animation-fill-mode: both;
  animation-name: rotate-wrapper;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

#corpus {
  background: $corpus-color;
  filter: blur(10px);
  position: absolute;
  top: 10px;
  left: 10px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

#spinner {
  border-radius: 50%;
  width: 20px;
  left: 40px;
  bottom: 50px;
  height: 20px;
  position: absolute;
  filter: blur(5px);

  animation-duration: 6s;
  animation-fill-mode: both;
  animation-name: rotate-spinner;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  &:after {
    content: '';
    position: absolute;
    width: 20px;
    height: 60px;
    background: white;
    filter: blur(5px);
  }
}

#text {
  position: absolute;
  width: 100px;
  height: 40px;
  top: 50%;
  left: 50%;
  margin: 60px 0 0 -50px;
  font-family: 'Comfortaa', cursive, sans-serif;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: $text-color;
  z-index: 10;
}

@keyframes rotate-wrapper {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes rotate-spinner {
  0% {
    transform: rotate(0deg) scale(2, 1.5) translate(0px, 25px);
  }
  25% {
    transform: rotate(270deg) scale(1.5) translate(0px, 20px);
  }
  50% {
    transform: rotate(540deg) scale(2) translate(5px, 15px);
  }
  75% {
    transform: rotate(810deg) scale(1, 3) translate(0px, 15px);
  }
  100% {
    transform: rotate(1080deg) scale(2, 1.5) translate(0px, 25px);
  }
}
