@import './fonts/fonts';
@import './button/button';
@import './form/form.scss';
@import './select/select.scss';

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.ant-notification .ant-notification-notice {
  padding: 0.625rem;

  .ant-notification-notice-with-icon {
    display: flex;
    align-items: center;
    .anticon {
      font-size: 1rem;
    }
    .ant-notification-notice-message {
      margin-inline-start: 0px;
      padding-inline-end: 0px;
    }
    .ant-notification-notice-description {
      margin-inline-start: 1.5rem;
    }
  }
  .ant-notification-notice-close {
    top: 0.625rem;
  }
}
